<template>
  <div class="SellingPrice">
    <van-pull-refresh v-model="refreshing" success-text="加载成功" @refresh="onRefresh" style="min-height: 100vh;">
        <template v-if="ishow">
       <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
        </template>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          v-if="!ishow"
        >
<!--            <div class="cell"  v-for="(item,index) in list" :key="index" @click="ToSellPricingDetails(item)">
                <div class="leftLine" :style="{background:item.status == 'FAIL'?'#FFB734':'#CC0E0F'}"></div>
                <div class="content">
                    <div class="top">
                        <div class="title">
                             <div class="flex">
                               <div class="icon" v-if="item.status == 'UNDELIVERED'">
                                 <input type="checkbox" class="checkBox" name="checkBoxTest" :value="item"  v-model="checkList" @change="checkBox" @click.stop>
                               </div>
                               <div class="name">{{item.goods[0].name}} {{item.weight.toFixed(2)}}g</div>
                             </div>
                             <div class="state" :class="{stateGreen:item.status == 'DONE' || item.status == 'VIOLATE',stateash:item.status == 'FAIL' || item.status == 'CANCEL'}" :style="{color:item.status == 'DONE' || item.status == 'VIOLATE'?'#02790D':item.status == 'FAIL' || item.status == 'CANCEL'?'#999999':'#FF2D2E'}">
                                 {{item.status == 'SUSPENSE'?'待审核':item.status == 'UNDELIVERED'?'待交料':item.status == 'DONE' || item.status == 'VIOLATE'?'已完成' :item.status == 'RECEIVE' ?'待处理':item.status == 'CANCEL'?'已取消':'已拒绝'}}
                            </div>
                        </div>
                        <div class="color999" v-if="item.hasOwnProperty('businessType')">业务类型 : {{item.businessType == '0'?'近期交料': item.businessType == '3'?'90天内交料': '90天内交料(大)'}}</div>
                        <div class="color999">未交料重量 :   {{item.freeWeight.toFixed(2)}}g</div>
                    </div>
                    <div class="bottom">
                        <div class="color999 van-ellipsis">订单单号: {{item.oid}}</div>
                        <div class="color999">{{item.createTime | formatDate_ }}</div>
                    </div>
                </div>
            </div>-->
          <div class="cell1" v-for="(item,index) in list" :key="index" @click="ToSellPricingDetails(item)">
            <div class="top">
              <div class="flex">
                <div class="icon" v-if="item.status == 'UNDELIVERED' && activeName === 'SUSPENSE,UNDELIVERED'">
                  <input type="checkbox" class="checkBox" name="checkBoxTest" :value="item"  v-model="checkList" @change="checkBox" @click.stop>
                </div>
                <div class="name">{{item.goods[0].name}} ({{item.businessType == '0'?'近': item.businessType == '3'?'延': '延'}})</div>
              </div>
              <div class="state" :class="{stateGreen:item.status == 'DONE' || item.status == 'VIOLATE',stateash:item.status == 'FAIL' || item.status == 'CANCEL'}" :style="{color:item.status == 'DONE' || item.status == 'VIOLATE'?'#02790D':item.status == 'FAIL' || item.status == 'CANCEL'?'#999999':'#FF2D2E'}">
                {{item.status == 'SUSPENSE'?'待审核':item.status == 'UNDELIVERED'?'待交料':item.status == 'DONE' || item.status == 'VIOLATE'?'已完成' :item.status == 'RECEIVE' ?'待处理':item.status == 'CANCEL'?'已取消':'已拒绝'}}
              </div>
            </div>
            <div class="extra">
              <div class="color999">{{item.createTime | formatDate_ }}</div>
              <div class="color999">定价单价: {{item.goods[0].price }}元/克</div>
            </div>
            <div class="body">
              <div class="weight">
                <div class="number">{{item.weight.toFixed(2)}}</div>
                <div class="text">定价重量(克)</div>
              </div>
              <div class="weight">
                <div class="number">{{ item.status == 'FAIL' || item.status == 'CANCEL' ? "--" : item.status == 'VIOLATE' ? item.weight.toFixed(2) : (item.weight - item.freeWeight).toFixed(2)}}</div>
                <div class="text">已结重量(克)</div>
              </div>
              <div class="weight">
                <div class="number" :style="{color:item.status == 'FAIL' || item.status == 'CANCEL'?'#999999':'#5473ff'}">{{ item.status == 'FAIL' || item.status == 'CANCEL' ? "--" : item.status == 'VIOLATE' ? "0.00" : item.freeWeight.toFixed(2)}}</div>
                <div class="text">剩余重量(克)</div>
              </div>
            </div>
          </div>
        </van-list>
      <van-dialog v-model="statementShow" :show-confirm-button="false" get-container="#app" >
        <div class="dialog_title">交料</div>
        <div class="centerbox_dialog">
          <div class="flex_between col999">  <div>快递费 (元)</div>  <div>{{userInfo.expressFee}}</div> </div>
          <div class="flex_between col999">   <div>保险费（元）</div>  <div>{{userInfo.insuranceFee}}</div></div>
          <div class="flex_between col999">   <div>首饰折价（元）</div>  <div>{{userInfo.purificationFee}}</div></div>
          <div class="flex_between col999">   <div>小金库剩余黄金（克）</div>  <div>{{userInfo.goldIncomingFreeWeight}}</div></div>
          <div class="flex_between break">   <div>交料克重（克）</div>  <div>{{referenceWeight}}</div></div>
        </div>
        <div class="dialog_title" style="text-align: left; margin-bottom: 0">明细</div>
        <div class="referlist flex_between">
          <div class="referlistDetail">定价价格</div>
          <div class="referlistDetail">定价克重</div>
          <div class="referlistDetail">剩余克重</div>
          <div class="referlistDetail">引用克重</div>
        </div>
        <div class="referlist flex_between" v-for="(item,index) in orderList" :key="index">
          <div class="referlistDetail">{{item.goods[0].price}}</div>
          <div class="referlistDetail">{{item.weight}}</div>
          <div class="referlistDetail">{{item.freeWeight}}</div>
          <div class="referlistDetail">{{item.referWeight}}</div>
        </div>
        <div class="flex_between popbtn">
          <div class="gocer closebtn" @click="close" >取消</div>
          <div class="gocer confirmbtn" @click="commit">确定</div>
        </div>
      </van-dialog>
    </van-pull-refresh>

  </div>
</template>

<script>
import *as api from'@/utils/api'
import Decimal from "decimal.js";
import moment from "moment";
export default {
    props:['activeName'],
    data() {
        return {
             list: [],
             loading: false,
             finished: false,
             refreshing: false,
             page:0,
             size:7,
            //  没有数据显示的
             ishow:true,
          //  复选框选中的数据
          checkList:[],
          orderList:[],
          statementShow: false, // 自主开来料结算单是否显示
          referenceWeight: "", // 引用订单克重
          userInfo: "", // 用户信息
          isClick: false, // 结算多次点击判断
        }
    },
methods: {
    ToSellPricingDetails(item){
            localStorage.setItem('SellPricingDetails',JSON.stringify(item))
            this.$router.push({
                name:'sellPricingDetails',
                query:{id:item.oid}
            })
        },
    onLoad() {
        setTimeout(async() => {
            try{
                let res = await api.getSellingPriceList({
                    page:this.page,
                    size:this.size,
                    status:this.activeName})
                // 如果加载完数据了 last为true
                // 把上一段 number赋值给page
                this.page = res.number +1

                // 把数组里的每一项一一铺开添加
                this.list.push(...res.content)

                // console.log(this.list);
                // 本次加载完毕，就应该把loading改成false，代表本次加载完了，让加载中状态消失
                // 这样才可以继续下次加载
                   this.loading = false;
                if (res.last == true) {
                    this.finished = true;
                }
              }catch{
                    this.ishow = true
              }finally{

              }
            },1000)

        },
async   onRefresh() {
                this.finished = false
            try{
                        //请求最新的数据
                let res =  await api.getSellingPriceList({
                    page:0,
                    size:this.size,
                    status:this.activeName})
                        this.page = res.number+1
                        this.list = res.content
                console.log('1',this.list);
                }catch{
                        this.ishow = true
                }finally{
                    if (this.list.length == 0) {
                        this.ishow = true
                        this.refreshing = false
                        return false
                    }else{
                        this.ishow = false
                    }
                        this.refreshing = false
                        this.loading = false
                    }
        },
  checkBox(e){
      if (!event.target.checked) {
        // 取消勾选
        console.log(this.checkList)
        this.checkList = this.checkList.filter(function(item, index){
          return item.createTime <  event.target._value.createTime
        })
      }
      if (!this.finished) {
        this.checkList = this.checkList.filter(function(item, index){
          return item.oid !==  event.target._value.oid
        })
        event.target.checked = false
        this.$toast('请下拉到底按序结算订单!')
        return
      }
    //  声明变量用来存储重量和金额
    let weight = new Decimal(0)
    if (this.checkList.length > 10) {
      this.checkList = this.checkList.filter(function(item, index){
        return item.oid !==  event.target._value.oid
      })
      event.target.checked = false
      this.$toast('单次最多结算10个定价单!')
      return
    }
    for(var i = 0, lenI = this.list.length; i < lenI; i++) {
      if (this.list[i].oid == event.target._value.oid) {
        if (i !== this.list.length-1) {
          if (this.checkList.indexOf(this.list[i+1]) === -1 ) {
            this.checkList = this.checkList.filter(function(item, index){
              return item.oid !==  event.target._value.oid
            })
            event.target.checked = false
            this.$toast('请按序结算订单!')
            return
          }
        }
        break
      }
    }
    this.orderList =[]
    this.checkList.forEach(item =>{
      // 遍历复选框选中的数据 算法添加到变量中
      weight = weight.add(new Decimal(item.freeWeight))
      // 订单单号
      this.orderList.push(item)
    })
    this.referenceWeight = weight.toNumber()
    this.$emit('getData',{weight: this.referenceWeight})
    return false
  },
  // 交料按钮点击时间
  async confirmCreateStatement() {
    this.userInfo = await api.getuser()
    let weightFree = new Decimal(this.userInfo.goldIncomingFreeWeight)
    let referenceWeightCopy = new Decimal(0)  // 计算引用克重
    this.orderList.forEach((item, index) => {
      if (weightFree.toNumber() === 0) {
        // 可用克重不够赋值引用克重0
        item["referWeight"] = 0
      } else {
        if (weightFree <= item.freeWeight) {
          // 可用克重<剩余克重，可用克重赋值引用克重
          item["referWeight"] = weightFree.toNumber()
          weightFree = weightFree.sub(weightFree)
        } else {
          // 可用克重>剩余克重，赋值剩余克重，可用克重减去赋值克重
          item["referWeight"] = item.freeWeight
          weightFree = weightFree.sub(new Decimal(item.freeWeight))
        }
      }
      // 引用克重相加
      referenceWeightCopy = referenceWeightCopy.add(new Decimal(item["referWeight"]))
    })
    this.referenceWeight = referenceWeightCopy.toNumber()
    this.statementShow = true
  },
  // 切换组件
  tabchange() {
     this.statementShow = false
    this.isClick = false
    this.referenceWeight = ""
    this.orderList = []
    this.checkList = []
  },
  // 关闭弹窗
  close() {
    this.statementShow = false
  },
  async commit() {
    if(this.isClick){
      setTimeout(() => {
        this.isClick = false
      }, 1000)
      return false
    }
    this.isClick = true
    if(!this.referenceWeight) {
      this.$toast('可用折足克重不足!')
      this.isClick = false
      return
    }
    if (this.orderList.length > 0) {
      const orderListCopy = this.orderList.filter(function(item, index){
        return item.referWeight !==  0
      })
      const orderIdList = []
      let money = new Decimal('0')
      let storageFee = new Decimal('0')
      orderListCopy.forEach( (item, index)=> {
        let newStorageFee = item.unfinishStorageFee ?? 0
        if (item.businessType !== "0") {
          const days = moment().diff(moment(item.createTime*1000), 'days')
          if (days > 30 && newStorageFee) {
            // 多次引用情况
            if (item.goods[0].freeWeight !== item.goods[0].weight) {
              newStorageFee = new Decimal(new Decimal(newStorageFee).toFixed(2, Decimal.ROUND_HALF_UP)).toNumber()
            } else {
              newStorageFee = new Decimal(new Decimal(newStorageFee).sub(new Decimal(0.3).mul(new Decimal(item.goods[0].freeWeight))).toFixed(2, Decimal.ROUND_HALF_UP)).toNumber()
            }
          } else {
            newStorageFee = 0
          }
        }
        orderListCopy[index] = {
          orderId: item.oid,
          goods: {
            id: item.goods[0].id,
            key: item.goods[0].key,
            name: item.goods[0].name,
          },
          weight: 0,
          price: item.goods[0].price,
          storageFee: newStorageFee,
          unfinishStorageFee:newStorageFee,
          discountWeight: item.referWeight,
          afterWeight: item.goods[0].weight,
          percent: 100,
          money: new Decimal(item.goods[0].price).mul(new Decimal(item.referWeight??0)).toDecimalPlaces(2, Decimal.ROUND_FLOOR).toNumber(),
          statementOrderType: "SELL_STATEMENT",
          fromPlatform: item.fromPlatform,
          simpleMarket: item.simpleMarket,
          priceId: item.priceId,
          sort: index,
          extra: {
            orderWeight: item.referWeight,
            freeWeight: item.goods[0].freeWeight,
          }
        }
        orderIdList.push(item.oid)
        money = money.plus(new Decimal(orderListCopy[index].money?orderListCopy[index].money:0))
        storageFee = storageFee.plus(new Decimal(orderListCopy[index].unfinishStorageFee?orderListCopy[index].unfinishStorageFee:0).toFixed(2, Decimal.ROUND_HALF_UP))
      })
      money = money
        .minus(new Decimal(this.userInfo.purificationFee?this.userInfo.purificationFee:0))
        .minus(new Decimal(this.userInfo.expressFee?this.userInfo.expressFee:0))
        .minus(new Decimal(this.userInfo.insuranceFee?this.userInfo.insuranceFee:0))
        .minus(new Decimal(storageFee?storageFee:0))
      const postData = {
        baiyinReferenceWeight: 0,
        expressMoney: this.userInfo.expressFee,
        goldReferenceWeight: this.referenceWeight,
        insFee: this.userInfo.insuranceFee,
        items: orderListCopy,
        money: (new Decimal(money.toFixed(2, Decimal.ROUND_HALF_UP))).toNumber(),
        orderIdList: orderIdList,
        pdReferenceWeight: 0,
        ptReferenceWeight: 0,
        zsReferenceWeight: 0,
        purificationFee: this.userInfo.purificationFee,
        storageFee: (new Decimal(storageFee.toFixed(2, Decimal.ROUND_FLOOR))).toNumber(),
        totalDiscountWeight: this.referenceWeight,
        totalWeight: 0,
        userId: this.userInfo.uid,
      }
      await api.statementCommon("SELL", postData)
      this.$toast.success({
          message: "提交成功,请核对确认结算单",
          duration: 2000,
          onClose: async () => {
          this.statementShow = false
          this.orderList = []
          this.referenceWeight = ""
          this.isClick = false
          this.$router.push({ name: "person" });
        }
      })
    }
  }
  },
    created(){
        this.onRefresh()
    },
}
</script>

<style lang="scss" scoped>
#loadingbox {
  margin-top: 150px;
}
.van-empty {
  margin-top: 150px;
}
::v-deep{
    .van-loading__text{
        font-size: 30px;
    }
    .van-list__finished-text{
        font-size: 30px;
    }
    .van-pull-refresh__head{
      font-size: 30px !important;
    }
    .van-empty__image{
        width: 420px;
        height: 260px;
    }
}
.SellingPrice{
    background-color: #f5f5f5;
}
.van-list{
    padding:  30px 30px 50px;
}
/*.top{
    border-bottom: 1px solid #E9E9E9;
    padding: 10px 0 20px;
}*/
.cell{
    background-color: #fff;
    display: flex;
    border-radius: 4px 4px 4px 4px;
    margin-bottom: 30px;
    overflow: hidden;
    .content{
        width: 100%;
        padding:20px;
        .title{
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        .weight{
            text-align: center;
        }
        .state{
            text-align: center;
            padding: 6px 14px;
            border: 1px solid #FF2D2E;
            border-radius: 50px;
        }
        .stateash{
            text-align: center;
            padding: 6px 14px;
            border: 1px solid #999999;
            border-radius: 50px;
        }
        .stateGreen{
            text-align: center;
            padding: 6px 14px;
            border: 1px solid green;
            border-radius: 50px;
        }
        .name{
            color: #242424;
            font-weight: 500;
            font-size: 32px;
        }
        }
        .bottom{
        display: flex;
        justify-content: space-between;
        padding: 16px 0 0;
        .color999{
            font-size: 24px;
        }
        }
        .color999{
            color: #999999;
            font-size: 28px;
            margin: 10px 0 0 0;
        }
    }
    .leftLine{
        width: 8px;
        height: 260px;
        background: #CC0E0F;
        border-radius: 8px 0px 0px 8px;
        opacity: 1;
    }
}
.cell1 {
  background-color: #ffffff;
  padding: 24px 32px;
  margin-bottom: 24px;
  .top {
    display: flex;
    font-size: 28px;
    justify-content: space-between;
    .name {
      font-weight: 500;
      font-size: 32px;
      color: #242424;
    }
    .state{
      text-align: center;
      padding: 6px 14px;
      border: 1px solid #FF2D2E;
      border-radius: 50px;
    }
    .stateash{
      text-align: center;
      padding: 6px 14px;
      border: 1px solid #999999;
      border-radius: 50px;
    }
    .stateGreen{
      text-align: center;
      padding: 6px 14px;
      border: 1px solid green;
      border-radius: 50px;
    }
  }
  .extra {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .color999{
      font-size: 24px;
      color: #999999;
    }
  }
  .body {
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
    padding: 32px 0;
    margin-top: 24px;
    .weight {
      width: 33%;
      text-align: center;
      .number {
        font-size: 32px;
        font-weight: 500;
        color: #242424;
      }
      .text {
        margin-top: 12px;
        font-size: 24px;
        color: #999999;
      }
    }
  }
}
.color999.van-ellipsis {
    width: 330px;
}
input[type="checkbox"]{
  width:30px;
  height:30px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  border-radius: 50%;
  position: relative;
  top: 2px;
  margin-right: 10px;
}
input[type="checkbox"]::before{
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  background: #f5f5f5;
  width:30px;
  height: 30px;
  border: 1px solid #e9e9e9;
  border-radius: 50%;}

input[type="checkbox"]:checked::before{
  content: "\2713";
  background-color: #FF2D2E;
  position: absolute;
  top: -6px;
  left: -6px;
  width:30px;
  height: 30px;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  color:#fff;
  line-height: 34px;
  font-size: 24px;
  font-weight: bold;
}
.van-dialog{
  width: 90%;
  padding: 0 40px 30px;
  .title{
    color: #242424;
  }
  .van-field{
    font-size: 28px;
  }
}
.popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }

}
.dialog_title{
  font-size: 32px;
  text-align: center;
  margin: 15px 0;
  color: #242424;
}
.referlist {
  border-bottom: 1px solid #cccccc;
  height: 50px;
  font-size: 24px;
  .referlistDetail {
    width: 25%;
    text-align: center;
  }
}
.break{
  padding: 2px 0;
  color: #242424;
}
.col999{
  padding: 2px 0;
  color: #999999;
}
.gocer{
  font-size: 32px;
  padding: 14px 0;
  margin-top: 30px;
  color:#fff;
  text-align: center;
}
.flex_between{
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}
.centerbox_dialog{
  text-align: left;
  // padding: 0 10px;
  font-size: 28px;
}
.closebtn{
  background-color: #D1D1D1;
}
.confirmbtn{
  background-color:#FF2D2E ;
}
.tips {
  margin-top: 14px;
  color: #ff2d2e;
  font-size: 20px;
}
</style>
